import React from "react";
import Content from "../components/Common/Content";
import Layout from "../components/Common/Layout";
import Main from "../components/Common/Main";
import Paragraph from "../components/Common/Paragrpah";
import Section from "../components/Common/Section";
import Subtitle from "../components/Common/Subtitle";
import Title from "../components/Common/Title";
import Spacing from "../constants/Spacing";
import styled from "styled-components";
import Color from "../constants/Color";

const OutsideLink = styled.a`
    font-weight: 400;
    line-height: 19px;
    color: ${Color.BRAND_1};
`;

const CookiePolicy = () => {
    return (
        <Layout title="Cookie Policy">
            <Main>
                <Section marginBottom={Spacing.SPACING_7}>
                    <Content>
                        <Title
                            marginBottom={Spacing.SPACING_3}
                            marginTop={Spacing.SPACING_3}
                        >
                            Cookie Policy
                        </Title>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            In this Cookie Policy we will provide you with
                            detailed information on how Čaljkušić Technology
                            d.o.o. (hereinafter - the "we" or "our"), shall
                            undertake to ensure the security of personal
                            information and the protection of rights of the
                            visitors and users of the websites (hereinafter -
                            the "Visitors", "You") while you use our websites
                            including but not limited to{" "}
                            <OutsideLink
                                href="https://www.learncmd.io/"
                                target="_blank"
                            >
                                https://www.learncmd.io/
                            </OutsideLink>{" "}
                            (hereinafter - the "Site") and the content on it.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            1. WHAT IS A COOKIE?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            A cookie is a small file placed onto your device
                            that enables our Site features and functionality.
                            For example, cookies can enable us to identify your
                            device and secure your access to the Site. Cookies
                            also allow the Site to remember information about
                            your browsing on the Site for a while and to
                            recognize you the next time you visit the Site. All
                            this allows us to give you the opportunity to use
                            the Site comfortably and to make the Site even more
                            user-friendly.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            2. WHY DO WE USE COOKIES?
                        </Subtitle>
                        <Paragraph>
                            We use cookies for the following main purposes:
                        </Paragraph>
                        <ul>
                            <li>
                                <Paragraph>
                                    To ensure efficient and safe functioning of
                                    the Site. We use cookies to enable and
                                    support our security features, and to help
                                    us detect malicious activity on our Site.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    To understand, improve, and research
                                    products, features, and services, including
                                    when you access our Site from other websites
                                    or devices such as your computer or your
                                    mobile device.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    To recognize the returning visitors of the
                                    Site. Cookies help us show you the right
                                    information and personalize your experience.
                                    Cookies also help avoiding re-registration
                                    or re-filling of the information by you each
                                    time you visit the Site.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    To analyze your habits so that the
                                    functioning of the Site would be convenient,
                                    efficient and would conform to your needs
                                    and expectations.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    To measure the flows of the information and
                                    data being sent to our Site. We use the
                                    cookies for accumulation of statistical data
                                    about the number of users of the Site and
                                    their use of the Site.
                                </Paragraph>
                            </li>
                        </ul>
                        <Subtitle
                            marginBottom={Spacing.SPACING_2}
                            marginTop={Spacing.SPACING_3}
                        >
                            3. WHAT COOKIES DO WE USE?
                        </Subtitle>
                        <Paragraph>
                            Each time you visit our Site, the long-term
                            (persistent) cookies may be created, which stay in
                            your browser after you sign-up and will be read by
                            us when you return to our Site and not deleted after
                            you finish browsing our Site, and the short-term
                            (session) cookies, which expire or are deleted after
                            you finish browsing our Site (i.e. they usually last
                            during the current visit to our Site or browsing
                            session).
                            <br />
                            <br />
                            Cookies used by the Company:
                        </Paragraph>
                        <ul>
                            <li>
                                <Paragraph>
                                    Strictly required or necessary cookies.
                                    These cookies are required for the operation
                                    of our Site. They include, for example,
                                    cookies that enable storage of information
                                    filled by you during the browsing session,
                                    enable you to log into secure areas of our
                                    Site. Without these cookies operation of the
                                    Site would be impossible or its functioning
                                    may be severely affected.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Preferences cookies. These improve the
                                    functional performance of our Site and make
                                    it easier for you to use. These cookies
                                    remember the settings selected by the
                                    Visitors (for example, the settings of
                                    language or currency). With the use of these
                                    cookies, the Visitors may avoid the changes
                                    of settings during each visit of the Site.
                                    These cookies also remember changes made by
                                    you in the Site (for example, in case you
                                    leave comment on the Site). These cookies do
                                    not track your behavior in other websites.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    Analytics and Statistics. These cookies show
                                    us if the Visitor has visited our Site
                                    before. The analytic cookies allow us to
                                    recognize and count the number of users of
                                    our website and see how such users navigate
                                    through our Site. We also use cookies to
                                    understand, improve, and research products,
                                    features, and services. For instance,
                                    analytical cookies may show us, which
                                    websites are visited more frequently, help
                                    us to record dysfunctionalities of the Site,
                                    etc.
                                </Paragraph>
                            </li>
                        </ul>
                        <Paragraph>Third-party cookies:</Paragraph>
                        <ul>
                            <li>
                                <Paragraph>
                                    We use Google Analytics, a web analysis
                                    service provided by Google, Inc (hereinafter
                                    referred to as "Google"). The information
                                    collected by Google Analytics is transmitted
                                    to and stored with Google. Google may
                                    transmit the information collected by Google
                                    Analytics to the third parties as required
                                    by the law or when those third parties
                                    process the information in the name of
                                    Google. We recommend consulting the Google
                                    Privacy and Cookies Policy on a separate and
                                    regular basis.
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>
                                    We use Hotjar in order to better understand
                                    our users’ needs and to optimize this
                                    service and experience. Hotjar is a
                                    technology service that helps us better
                                    understand our users’ experience (e.g. how
                                    much time they spend on which pages, which
                                    links they choose to click, what users do
                                    and don’t like, etc.) and this enables us to
                                    build and maintain our service with user
                                    feedback. Hotjar uses cookies and other
                                    technologies to collect data on our users’
                                    behavior and their devices. This includes a
                                    device's IP address (processed during your
                                    session and stored in a de-identified form),
                                    device screen size, device type (unique
                                    device identifiers), browser information,
                                    geographic location (country only), and the
                                    preferred language used to display our
                                    website. Hotjar stores this information on
                                    our behalf in a pseudonymized user profile.
                                    Hotjar is contractually forbidden to sell
                                    any of the data collected on our behalf.
                                    <br />
                                    For further details, please see the ‘about
                                    Hotjar’ section of Hotjar’s support site:{" "}
                                    <OutsideLink
                                        href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"
                                        target="_blank"
                                    >
                                        https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar
                                    </OutsideLink>{" "}
                                </Paragraph>
                            </li>
                        </ul>
                        <Subtitle
                            marginBottom={Spacing.SPACING_2}
                            marginTop={Spacing.SPACING_3}
                        >
                            4. HOW TO REFUSE OR BLOCK COOKIES?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            Many web browsers are set so that they would
                            automatically accept all cookies.
                            <br />
                            <br />
                            You may refuse and make a preference which cookies
                            you allow by choosing it in our Cookie Consent
                            Banner. Also all the cookies will be set if you
                            accept all by clicking "Accept Cookies" on our Cookie
                            Consent Banner.
                            <br />
                            <br />
                            The Visitors may, at their discretion, manage, block
                            or delete cookies, if the settings of their browser
                            or device enable them to do so. Nevertheless, if you
                            refuse or block the cookies or other similar
                            technologies, some functions of the Site may be
                            inaccessible to you or they may operate not
                            properly.The Visitors may, at their discretion,
                            manage, block or delete cookies, if the settings of
                            their browser or device enable them to do so.
                            Nevertheless, if you refuse or block the cookies or
                            other similar technologies, some functions of the
                            Site may be inaccessible to you or they may operate
                            not properly.
                            <br />
                            <br />
                            We draw your attention that necessary cookies are
                            critical for functioning of our Site, and in case of
                            your objections, some features of the Site may not
                            work or may not work properly.
                            <br />
                            <br />
                            You may require that we delete all the data about
                            you, as collected and processed with the help of the
                            cookies, by contacting to the email address{" "}
                            <OutsideLink>team@learncmd.io</OutsideLink>.
                            <br />
                            <br />
                            You can opt out of Google Analytics without
                            affecting how you visit our Site. For more
                            information on opting out of being tracked by Google
                            Analytics across all websites you use, visit this
                            Google page:{" "}
                            <OutsideLink
                                href="https://tools.google.com/dlpage/gaoptout"
                                target="_blank"
                            >
                                https://tools.google.com/dlpage/gaoptout
                            </OutsideLink>
                            .
                            <br />
                            <br />
                            You may find more information about how to delete
                            cookies, as well as the other useful information
                            related to the use of the cookies, on the website{" "}
                            <OutsideLink
                                href="https://www.allaboutcookies.org/"
                                target="_blank"
                            >
                                https://www.allaboutcookies.org/
                            </OutsideLink>
                            .
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            5. DO WE UPDATE COOKIE POLICY?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            This Cookie Policy may be updated by us from time to
                            time. We will inform you about the updates, by
                            providing the new version of the Cookie Policy. For
                            this reason, we recommend you periodically visit our
                            Site, where you will always find the latest version
                            of this Cookie Policy.
                        </Paragraph>
                    </Content>
                </Section>
            </Main>
        </Layout>
    );
};

export default CookiePolicy;
